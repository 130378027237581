<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container" v-if="applications">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReferenceManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.references') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left"></div>
										<div class="table__filter--right">
											<router-link
												v-if="$store.getters.userPermission(['change_catalog'])"
												:to="
													'/' +
													$i18n.locale +
													'/account/references/' +
													this.$route.params.id +
													'/create'
												"
												class="btn--link btn--link--plus"
											>
												{{ $t('button.add') }} <img src="../../../assets/img/icon-plus.svg" />
											</router-link>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="listBody"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
									>
										<template v-slot:item.created_at="{ item }">
											{{ item.created_at | formatDate }}
										</template>
										<template v-slot:item.action="{ item }">
											<router-link
												v-if="$store.getters.userPermission(['change_catalog'])"
												:to="
													'/' +
													$i18n.locale +
													'/account/references/' +
													$route.params.id +
													'/entry/' +
													item.id
												"
												class="tb__table--btn tb__table--edit"
												v-tooltip.top-center="{
													content: $t('tooltip.edit'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9 15h6.75M12.375 2.625a1.591 1.591 0 112.25 2.25L5.25 14.25l-3 .75.75-3 9.375-9.375z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
											<a
												v-if="$store.getters.userPermission(['change_catalog'])"
												class="tb__table--btn tb__table--delete"
												@click="$modal.show('modal_delete'), (blockElemet = item)"
												v-tooltip.top-center="{
													content: $t('button.delete'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</a>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>

									<modal
										class="modal__block modal__block--middle modal__block--delete"
										name="modal_delete"
										:width="550"
										:min-width="550"
										height="auto"
									>
										<div class="modal__block--wrapper">
											<div class="modal__block--close" @click="$modal.hide('modal_delete')">
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</div>
											<div class="modal__wrapper">
												<div class="modal__header">
													<div class="modal__title">
														Вы уверены что хотите удалить данные<br />
														<strong>
															{{ blockElemet ? blockElemet.description : '' }}
														</strong>
													</div>
												</div>

												<div class="modal__content">
													<div class="modal__form">
														<div class="block__row">
															<div class="block__row--left">
																<a
																	class="block__item"
																	v-if="blockElemet"
																	@click="blockElement(blockElemet.id)"
																>
																	<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																	{{ $t('button.delete') }}
																</a>
															</div>
															<div class="block__row--right">
																<a class="block__item block__item--blue">
																	{{ $t('button.cancel') }}
																</a>
															</div>
														</div>
														<div
															class="form__block--line form__messages"
															v-if="message.status"
															:class="{
																'form__messages--error': message.status != 200,
																'form__messages--success': message.status == 200,
															}"
														>
															<label class="form__block--input">
																<span class="form__block--title">
																	{{ message.text }}
																</span>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</modal>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'
import { api } from '@/boot/axios'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			applications: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			blockElemet: null,

			listHeader: [],
			listBody: [],
			headers: [
				{ text: this.$t('headers.created_at'), value: 'created_at' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	methods: {
		blockElement(id) {
			api
				.post('catalog/' + this.$route.params.id + '/entry/' + id + '/delete', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.blockElemet = null
						this.$modal.hide('modal_delete')

						this.$router.go()
					}
				})
		},

		apiGetCatalogList() {
			api
				.get('catalog/' + this.$route.params.id + '/entry/list')
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
						let applications = response.data.data
						if (applications[0]) {
							this.listHeader.push({ text: 'Id', value: 'id' })
							applications[0].values.find((element, index) => {
								let key = 'field_' + element.column.id
								if (index < 3) {
									this.listHeader.push({ text: element.column.name, value: key })
								}
							})
							this.headers = this.listHeader.concat(this.headers)
							applications.find((element, index) => {
								let elementBody = new Array()
								element.values.find((item, index) => {
									elementBody['field_' + item.column_id] = item.value
								})
								elementBody['created_at'] = element.created_at
								elementBody['id'] = element.id
								this.listBody.push(Object.assign({}, elementBody))
							})
						}
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	computed: {
		filteredlist() {
			// return this.applications.filter((d) => {
			//   // if(d.value.toLowerCase().includes(this.filters.search.toLowerCase())){
			//     return true
			//   // }
			// });
		},
	},
	beforeMount() {
		this.apiGetCatalogList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: 'Главная',
			}
		},
		meta: [],
	},
}
</script>
